import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import 'styles/index.scss';
import 'helper/i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';
import liff from '@line/liff';
import { message } from 'antd';
import configs from 'config';
import { ConfigProvider } from 'antd';
import jaJP from 'antd/lib/locale/ja_JP';


if (configs?.LIFF_ID) {
    liff.init({ liffId: `${configs?.LIFF_ID}` }).catch((error) => message.error(error));
}

ReactDOM.render(
  <ConfigProvider locale={jaJP}>
    <App />
  </ConfigProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();