import configs from 'config';
import { message } from 'antd';

export const handleErrorMessage = (error: any) => {
  message.destroy();
  message.error(getErrorMessage(error));
  if (configs.APP_ENV !== 'prod') {
    // tslint:disable-next-line: no-console
    console.log(error, getErrorMessage(error));
  }
};

export const getErrorMessage = (error: any) => {
  console.log("response",error?.response )
  return error?.response?.data?.error?.message || 'Something went wrong!';
};
