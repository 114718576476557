import React, { Suspense, createContext } from 'react';
import { createBrowserHistory } from 'history';
import RootWrapper from './wrappers/RootWrapper';
import { Router } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import configs from 'config';
import 'moment/locale/ja';
import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/react";
// import { ModalWarrningBrowser } from 'pages/PopUpWarning';
import { ModalWarrningBrowser } from 'pages/PopUpServiceFinish';

import Amplify, { Auth } from "aws-amplify"
import awsExports from "./aws-exports";

import { AmplifyAuthenticator, AmplifySignUp, AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import './App.scss';

Amplify.configure(awsExports);


function FallbackComponent() {
  return <div>An error has occurred</div>;
}

const myFallback = <FallbackComponent />;



export const history = createBrowserHistory();
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 24 * 3600 * 1000, // cache for 1 day
      retry: false,
    },
  },
});

Sentry.init({
  dsn: configs.SENTRY,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

// Cognitoで作成したユーザー情報
type User = {
  id: string,
  username: string,
  attributes: {
    email: string
    sub: string // いわゆるUID的なもの（一意の識別子）
  }
}

export const UserContext = createContext({} as {
  userInfo: User | undefined
  setCurrentUser: React.Dispatch<React.SetStateAction<object | undefined>>
})

const AuthStateApp: React.FunctionComponent = () => {
  const [authState, setAuthState] = React.useState<AuthState>();
  const [currentUser, setCurrentUser] = React.useState<object | undefined>();
  const [userInfo, setUserInfo] = React.useState<User>()
  const [showModal, setShowModal] = React.useState<boolean>(false);

  const getUserInfo = async () => {
    const currentUserInfo = await Auth.currentUserInfo()
    setUserInfo(currentUserInfo)
  }


  React.useEffect(() => {
      return onAuthUIStateChange((nextAuthState, authData) => {
          setAuthState(nextAuthState);
          setCurrentUser(authData)
      });
  }, []);

  React.useEffect(() => {
    getUserInfo()
    setShowModal(true)
  }, [])

  return authState === AuthState.SignedIn && currentUser ? (
    <Sentry.ErrorBoundary fallback={myFallback} showDialog>
      <QueryClientProvider client={queryClient}>
        <Router history={history}>
          <UserContext.Provider value={{ userInfo, setCurrentUser }}>
            <Suspense fallback={null}>
              <RootWrapper />
            </Suspense>
          </UserContext.Provider>
        </Router>
        {configs.APP_ENV !== 'prod' && <ReactQueryDevtools initialIsOpen={false} />}
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  ) : (
      <AmplifyAuthenticator>
        <AmplifySignIn
          headerText="Kampo Mania Profess Service Sign In"
          hideSignUp={true}
          slot="sign-in"
          >
        </AmplifySignIn>
      <AmplifySignUp
        slot="sign-up"
        formFields={[
          { type: "username" },
          { type: "password" },
          { type: "email" }
        ]}
      />
      <ModalWarrningBrowser showModal={showModal} setShowModal={setShowModal} />
    </AmplifyAuthenticator>
  );
}

export default AuthStateApp;


/*
function App() {
  return (
    <Sentry.ErrorBoundary fallback={myFallback} showDialog>
    <QueryClientProvider client={queryClient}>
      <Router history={history}>
        <Suspense fallback={null}>
          <RootWrapper />
        </Suspense>
      </Router>
      {configs.APP_ENV !== 'prod' && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
    </Sentry.ErrorBoundary>

  );
}

export default App;

*/