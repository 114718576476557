import React, { lazy, useCallback, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthWrapper from 'wrappers/AuthWrapper';
import PageHeader from 'components/PageHeader';
import PageFooter from 'components/PageFooter';
import styles from './styles.module.scss';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';
import configs from 'config';

const StartScreen = lazy(() => import('pages/StartScreen'));
const SignUp = lazy(() => import('pages/SignUp'));
const verifyLogin = lazy(() => import('pages/Login/verifyLogin'));
const Login = lazy(() => import('pages/Step2/PopUp'));

export default function AppWrapper() {
  const params = useLocation();
  ReactGA.initialize(`${configs.GA}`);
  const pathGA: any = params?.pathname.split('/');
  const [isScroll, setIsScroll] = useState(0);

  // ReactGA.send({ hitType: "pageview", page: params.pathname });
  useEffect(() => {
    // ReactGA.event({
    //   category: `System_page_view_${params.pathname}`,
    //   action: `System_page_view_${params.pathname}`,
    // });
    setIsScroll(0);
    window.gtag('config', `${configs.GA}`);
    window.gtag('event', 'page_view', {
      page_title: `System_page_view_${pathGA[1]}`,
      page_location: `System_page_view_${pathGA[1]}`,
      page_path: `System_page_view_${pathGA[1]}`,
      send_to: `${configs.GA}`,
    });
  }, [params.pathname]);

  useEffect(() => {
    if (isScroll === 1) {
      ReactGA.event({
        category: `System_scroll_to_view_${pathGA[1]}`,
        action: `System_scroll_to_view_${pathGA[1]}`,
      });
    }
  }, [params.pathname, isScroll]);

  window.onscroll = function (ev) {
    if (isScroll < 2 && window.scrollY > 0) {
      setIsScroll(isScroll + 1);
    }
  };

  return (
    <div className="root-wrapper">
      <Switch>
        <Route path="/start-screen" component={StartScreen} />
        {/* <Route path="/login" component={Login} /> */}
        <Route path="/sign-up" exact component={SignUp} />
        <Route path="/verify-login" component={verifyLogin} />
        <Route path="/" component={AuthWrapper} />
      </Switch>
    </div>
  );
}
