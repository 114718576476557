import classNames from 'classnames';
import React, { useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';

import styles from './style.module.scss';
import iconDanger from 'assets/images/update/danger.svg';
import imageServiceFinish from 'assets/images/servicefinish.png';


interface IProps {
    showModal: boolean;
    setShowModal: (value: boolean) => void;
}

export const ModalWarrningBrowser = ({ showModal, setShowModal }: IProps) => {
    const { t } = useTranslation();


    const handleCancel = () => {
        setShowModal(false);
    };

    const redirectKampoMania = () => {
        window.open("https://www.kampomania.com", "_blank")
    }

                    // <img src={iconDanger} />
    return (
        <div className={styles.coverModalDanger}>
            <Modal title={null} visible={showModal} onCancel={handleCancel}
            >
                <div className={styles.containerImage}>
                    <img className={styles.image} src={imageServiceFinish} />
                </div>
                <div className={styles.coverDanger}>
                    <div className={styles.link} onClick={redirectKampoMania}> オンラインストアはこちらから</div>
                </div>
            </Modal>
        </div>
    );
}
