const configs = {
  APP_ENV: process.env.REACT_APP_ENV,
  API_DOMAIN: process.env.REACT_APP_API_DOMAIN,
  LIFF_ID: process.env.REACT_APP_LIFF_ID,
  APP_DOMAIN: process.env.REACT_APP_DOMAIN_FRONT,
  DOMAIN_VERIFY: process.env.REACT_APP_DOMAIN_VERIFY,
  DOMAIN_REDIRECT_LINE: process.env.REACT_APP_DOMAIN_REDIRECT_LINE,
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
  LINE_ID: process.env.REACT_APP_LINE_ID,
  SENTRY: process.env.REACT_APP_SENTRY,
  GA: process.env.REACT_APP_GA,
};

export default configs;
