import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import liff from '@line/liff';
import { useTranslation } from 'react-i18next';
import { handleErrorMessage } from 'helper';

import iconLogout from 'assets/images/logout.svg';
import iconHeader from 'assets/images/update/header.svg';
import { loadProfile } from 'api/profile';

export default function PageHeader() {
  const history = useHistory();
  const [checkLogin, setCheckLogin] = useState<boolean>(false);
  const [checkAnonymous, setCheckAnonymous] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleLogout = () => {
    history.push('/');
    Cookies.remove('token');
    Cookies.remove('refreshToken');
    localStorage.removeItem('lineId')
    liff.logout();
    window.location.reload();
    setCheckLogin(false)
  };

  useEffect(() => {
    if (Cookies.get('token') !== undefined) {
      setCheckLogin(true)
    }
  }, [Cookies.get('token')]);

  useEffect(() => {
    (async () => {
      try {
        const response = await loadProfile();
        const inforUser = response?.body || '';
        if (inforUser.anonymous) {
          setCheckAnonymous(true)
        }
      } catch (error) {
        handleErrorMessage(error)
      }
    })();
  }, []);

  const ridrectHome = () => {
    // history.push('/home');
    window.location.href = "/home"
  }

  return (
    <div className={styles.coverHeader}>
      <div className={styles.headerWrapper}>
        {checkAnonymous ? <img src={iconHeader} alt="" className={styles.logoKampo} onClick={handleLogout} /> : <img src={iconHeader} alt="" className={styles.logoKampo} onClick={ridrectHome} />}
        <div className={styles.coverLogoutHeader}>
          <div className={styles.iconHeader}>
            {(checkLogin && !checkAnonymous) ? <img src={iconLogout} onClick={handleLogout} /> : null}
          </div>
          <div className={styles.iconHeader2}>
            {(checkLogin && !checkAnonymous) ? <div>{t('header.logout')}</div> : null}
          </div>
        </div>

      </div>
    </div>
  );
}
